import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Auth } from './auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faChess } from '@fortawesome/free-solid-svg-icons';
import Popup from "./Popup";
import './App.css';

const auth = new Auth();

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  const scrollToFeatures = () => {
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleLogin = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <img src="./images/logo.png" alt="Archetyped logo" className="logo" />
          <div className="nav-buttons">
            <a href="https://chromewebstore.google.com/detail/archetyped-know-you-your/igcflbhhhlnomjdjcmamnlmkaiaoilpj?hl=en-US"><button className="cta-button">Download Extension</button></a>
            <button className="cta-button" onClick={handleLogin}>Login with Lichess</button>
          </div>
        </nav>
        <section className="hero">
  <div className="hero-content">
    <div className="image-container">
      <img src="/images/Landing.png" alt="Example Game w/ Archetyped" className="hero-image" />
    </div>
    <div className="text-container">
      <h2>The Archetypes</h2>
      <ul className="archetype-list">
        <li><strong className="arch">Trending</strong> - <i><strong>Playing better or worse than their elo</strong></i></li>
        <li> <strong class="performanceItems">
					<span class="performanceColorGood">Better</span>
					<span class="line"> | </span>
					<span class="performanceColorBad">Worse</span>
			</strong></li>
        <li><strong className="arch">In Flow</strong> - <i><strong>Won or lost a lot of games recently</strong></i></li>
        <li class="trending">
          <span><img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/fire_1f525.gif" alt="fire" class="emoji"></img></span>
          <span class="line"><strong> | </strong></span>
          <span><img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/snowflake_2744-fe0f.gif" alt="ice" class="emoji"></img></span></li>
        <li><strong className="arch">Wild Card</strong> - <i><strong>Tends to win or lose games they shouldn't</strong></i></li>
        <li class="trending">
          <span><img src="/images/uno.png"></img></span>
        </li>
        <li><strong className="arch">Devoted</strong> - <i><strong>Plays a ton of chess</strong></i></li>
        <li class="trending">
          <span><img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/thinking-face_1f914.gif"></img></span>
        </li>
        <li><strong className="arch">Speed</strong> - <i><strong>Plays Bullet, Blitz, or Rapid</strong></i></li>
        <li class="trending"><span><img src="/images/bullet.png" alt="bullet" class="emoji"></img></span>
                        <span class="line"><strong> | </strong></span>
                        <span><img src="/images/lightning.png" alt="blitz" class="emoji"></img></span>
                        <span class="line"><strong> | </strong></span>
                        <span><img src="/images/turtle.png" alt="rapid" class="emoji"></img></span></li>
      </ul>
      <div className="button-container">
        <button className="workButton" onClick={scrollToFeatures}>How Does It Work?</button>
      </div>
    </div>
  </div>
</section>
      </header>
      <section id="features" className="features">
        <span className="feature-title"><h2 className="step-title">How<img className="title-image" src="/images/logo.png"></img>Works</h2></span>
        <ul className="steps-list">
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong><a href="https://chromewebstore.google.com/detail/archetyped-know-you-your/igcflbhhhlnomjdjcmamnlmkaiaoilpj?hl=en-US" className="step-link">Download</a>the Google Extension</strong>
          </li>
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong>Get clear visual representations of your opponent's 'Archetypes' while playing games</strong>
          </li>
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong><a onClick={handleLogin} className="step-link">Login</a>here with your Lichess account to access all your stats</strong>
          </li>
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong>Spot key patterns in your results and opponent's gameplay to prepare better strategies</strong>
          </li>
        </ul>
      </section>
      <footer className="app-footer">
  <div className="footer-container">
    <div className="footer-links">
      <h4>Quick Links</h4>
      <ul className="footer-nav">
        <li><a href="https://chromewebstore.google.com/detail/archetyped-know-you-your/igcflbhhhlnomjdjcmamnlmkaiaoilpj?hl=en-US">Download</a></li>
        <li><a onClick={handleLogin}>Login</a></li>
        <li><a href="mailto:support@archetyped.lol">Contact Us</a></li>
      </ul>
    </div>
    <div className="footer-branding">
      <img className="footer-logo" src="/images/arch.png" alt="Archetyped Logo"></img>
      <p className="footer-description">Master the game with data-driven insights. Visualize, analyze, and improve your strategies with Archetyped.</p>
    </div>
    <div className="footer-social">
  <h4>Follow Us</h4>
  <ul className="social-icons">
    <li>
      <a href="https://twitter.com">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </li>
    <li>
      <a href="https://instagram.com">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </li>
    <li>
      <a href="https://lichess.org">
        <FontAwesomeIcon icon={faChess} />
      </a>
    </li>
  </ul>
</div>
  </div>

  <div className="footer-bottom">
    <p>&copy; 2024 Archetyped. All rights reserved.</p>
  </div>
</footer>

{isPopupOpen && <Popup onClose={closePopup} />}
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  );
}
