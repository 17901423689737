import React from "react";
import "./Popup.css"; // Assuming your popup CSS is in a separate file

const Popup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Welcome to Archetyped!</h2>
        <p>
          We are currently in <strong>'closed beta'</strong> with expected general rollout on Halloween 2024 🎃.
        </p>
        <p>
          Please contact <a href="mailto:support@archetyped.lol">support@archetyped.lol</a> for any inquiries.
        </p>
        <button onClick={onClose} className="close-popup-button">Close</button>
      </div>
    </div>
  );
}

export default Popup;